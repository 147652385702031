import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const FixTwoIcon = (props: SvgIconProps) => (
    <SvgIcon {...props}>
        <path
            d="M15 5.25L15.705 3.705L17.25 3L15.705 2.295L15 0.75L14.295 2.295L12.75 3L14.295 3.705L15 5.25Z"
            fill="currentColor"
        />
        <path
            d="M13.2824 6.84L11.1599 4.7175C11.0099 4.575 10.8224 4.5 10.6274 4.5C10.4324 4.5 10.2449 4.575 10.0949 4.7175L1.71742 13.095C1.42492 13.3875 1.42492 13.86 1.71742 14.1525L3.83992 16.275C3.98992 16.425 4.17742 16.5 4.37242 16.5C4.56742 16.5 4.75492 16.425 4.90492 16.2825L13.2824 7.905C13.5749 7.6125 13.5749 7.1325 13.2824 6.84ZM10.6274 6.315L11.6849 7.3725L10.8074 8.25L9.74992 7.1925L10.6274 6.315ZM4.37242 14.6925L3.31492 13.635L8.69242 8.25L9.74992 9.3075L4.37242 14.6925Z"
            fill="currentColor"
        />
    </SvgIcon>
);
