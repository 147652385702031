import type { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

const styles = (theme: Theme) =>
    ({
        collapseSection: {
            padding: theme.spacing(2),
            paddingBottom: theme.spacing(0),
        },
        accordionSummary: {
            '& .MuiAccordionSummary-content': { margin: 0 }, // otherwise too tall
            minHeight: 30, // otherwise too tall
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
        },
        settingsSection: {
            padding: theme.spacing(2),
            gap: theme.spacing(1.5),
        },
        propertyInput: {
            height: '36px',
            alignItems: 'center',
        },
        infoButton: {
            marginLeft: theme.spacing(1),
        },
    }) as const;

export const useStyles = makeStyles()(styles);
