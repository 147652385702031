import type { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

const styles = (theme: Theme) =>
    ({
        settingsSection: {
            padding: theme.spacing(2),
            gap: theme.spacing(1),
        },
        wireframeToggle: {
            padding: theme.spacing(0.75),
        },
        opacitySlider: {
            padding: theme.spacing(0.75),
            paddingTop: theme.spacing(3),
        },
        propertyInput: {
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        controlStack: {
            alignItems: 'center',
        },
    }) as const;

export const useStyles = makeStyles()(styles);
