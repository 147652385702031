import { GetObjectResponse } from '@local/api-clients/dist/goose/enhancedGooseClient';
import { useTrace } from '@local/web-design-system-2/dist/utils/trace';
import { useBaseXyz } from '@local/webviz/dist/context/hooks/useBaseXyz';
import { SurfaceViewState, Color as XyzColor } from '@local/webviz/dist/types/xyz';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import Slider from '@mui/material/Slider';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';

import { ColorPicker } from 'src/components/ColorPicker';
import { useObjectNonGeometryUpdate } from 'src/hooks/transformation/useObjectNonGeometryUpdate';
import { useSceneObjectDataManager } from 'src/hooks/useSceneObjectDataManager';
import { selectCurrentModelSelectedObject } from 'src/store/project/selectors';
import { useAppSelector } from 'src/store/store';
import { sceneObjectById } from 'src/store/visualization/selectors';
import { htmlColorToXyzColor, xyzColorToHtmlColor } from 'src/utils/typeTransformations';

import {
    WIREFRAME_LABEL,
    COLOUR_LABEL,
    OPACITY_LABEL,
    OFF_LABEL,
    ON_LABEL,
} from './GeometryAppearancePanel.constants';
import { useStyles } from './GeometryAppearancePanel.styles';

export function GeometryAppearancePanel() {
    const { classes } = useStyles();
    const applyTrace = useTrace('appearance-tab');

    return (
        <Stack
            direction="column"
            className={classes.settingsSection}
            automation-id={applyTrace('root-stack')}
        >
            <WireframeRow />
            <ColorRow />
            <OpacityRow />
        </Stack>
    );
}

const objectWithUpdatedAppearance = (
    gooseObject: GetObjectResponse,
    updatedProperty: any,
): GetObjectResponse => ({
    ...gooseObject,
    object: {
        ...gooseObject.object,
        extensions: {
            ...gooseObject.object.extensions,
            appearance: {
                ...gooseObject.object.extensions.appearance,
                ...updatedProperty,
            },
        },
    },
});

// TODO: is there a better way to lay this out than these fixed widths?
const controlColumnWidth = 175;

function WireframeRow() {
    const { classes } = useStyles();
    const { updateObjectWireframe } = useSceneObjectDataManager();
    const currentObjectRef = useAppSelector(selectCurrentModelSelectedObject)!;
    const geomObject = useAppSelector(sceneObjectById(currentObjectRef.id))!;
    const { objectNonGeometryUpdate, isLoading } = useObjectNonGeometryUpdate();
    const { getEntityState } = useBaseXyz();

    function isChecked(): boolean {
        const surfaceView = getEntityState(`${currentObjectRef.id}`) as SurfaceViewState;
        return surfaceView?.wireframe ?? false;
    }

    const handleChange = async (event: Event | React.SyntheticEvent, newValue: boolean) => {
        // Update the redux object
        const newObject = objectWithUpdatedAppearance(geomObject.gooseObject!, {
            wireframe: newValue,
        });

        // Update the XYZ object
        updateObjectWireframe(currentObjectRef.id, newValue);

        // Update goose and the store
        objectNonGeometryUpdate(newObject);
    };

    return (
        <Stack direction="row" className={classes.propertyInput}>
            <Typography variant="caption" color="secondary">
                {WIREFRAME_LABEL}
            </Typography>
            <Stack direction="row" width={controlColumnWidth} className={classes.controlStack}>
                <FormControlLabel
                    className={classes.wireframeToggle}
                    label={<Typography>{isChecked() ? ON_LABEL : OFF_LABEL}</Typography>}
                    color="primary"
                    control={
                        <Switch
                            size="small"
                            disabled={isLoading}
                            checked={isChecked()}
                            onChange={handleChange}
                        />
                    }
                />
            </Stack>
        </Stack>
    );
}

function ColorRow() {
    const { classes } = useStyles();
    const { updateObjectColor } = useSceneObjectDataManager();
    const currentObjectRef = useAppSelector(selectCurrentModelSelectedObject)!;
    const geomObject = useAppSelector(sceneObjectById(currentObjectRef.id))!;
    const applyTrace = useTrace('color-row');
    const [openColorPicker, setOpenColorPicker] = useState(false);
    const { objectNonGeometryUpdate, isLoading } = useObjectNonGeometryUpdate();
    const { getEntityState } = useBaseXyz();

    const handleColorPickerOnSave = async (color: string) => {
        const xyzColor = htmlColorToXyzColor(color);
        const newObject = objectWithUpdatedAppearance(geomObject.gooseObject!, {
            fillColor: xyzColor,
        });

        // Update the XYZ object
        updateObjectColor(currentObjectRef.id, xyzColor);

        // Update goose and the store
        objectNonGeometryUpdate(newObject);
    };

    const getColor = (): XyzColor => {
        const surfaceView = getEntityState(`${currentObjectRef.id}`) as SurfaceViewState;
        return surfaceView?.color ?? [255, 255, 0];
    };

    return (
        <Stack direction="row" className={classes.propertyInput}>
            <Typography variant="caption" color="secondary">
                {COLOUR_LABEL}
            </Typography>
            <Stack direction="row" width={controlColumnWidth} className={classes.controlStack}>
                <Box sx={{ position: 'relative' }}>
                    <IconButton
                        component="label"
                        size="medium"
                        disabled={isLoading}
                        onClick={() => {
                            setOpenColorPicker(true);
                        }}
                    >
                        <Icon
                            sx={{
                                borderRadius: '4px',
                                backgroundColor: xyzColorToHtmlColor(getColor()),
                                border: '1px solid #FAFCFF8F',
                            }}
                        />
                    </IconButton>
                    <ColorPicker
                        automation-id={applyTrace('color-picker')}
                        sx={{ position: 'absolute', right: '140px', top: '-32px' }}
                        open={openColorPicker}
                        onSave={handleColorPickerOnSave}
                        onClose={() => {
                            setOpenColorPicker(false);
                        }}
                        initialColor={xyzColorToHtmlColor(getColor())}
                    />
                </Box>
                <Typography
                    variant="body2"
                    color="secondary"
                    automation-id={applyTrace('color-text')}
                >
                    {xyzColorToHtmlColor(getColor())}
                </Typography>
            </Stack>
        </Stack>
    );
}

function OpacityRow() {
    const { classes } = useStyles();
    const { updateObjectOpacity } = useSceneObjectDataManager();
    const currentObjectRef = useAppSelector(selectCurrentModelSelectedObject)!;
    const geomObject = useAppSelector(sceneObjectById(currentObjectRef.id))!;
    const applyTrace = useTrace('opacity-row');
    const { objectNonGeometryUpdate, isLoading } = useObjectNonGeometryUpdate();
    const { getEntityState } = useBaseXyz();

    function getOpacityPercentage(): number {
        const surfaceView = getEntityState(`${currentObjectRef.id}`) as SurfaceViewState;
        return Math.round((surfaceView?.opacity ?? 1.0) * 100);
    }

    const handleChange = async (
        event: Event | React.SyntheticEvent,
        newValue: number | number[],
    ) => {
        const newOpacity = (newValue as number) / 100.0;
        const newObject = objectWithUpdatedAppearance(geomObject.gooseObject!, {
            opacity: newOpacity,
        });

        // Update the XYZ object
        updateObjectOpacity(currentObjectRef.id, newOpacity);

        // Update goose and the store
        objectNonGeometryUpdate(newObject);
    };

    return (
        <Stack direction="row" className={classes.propertyInput}>
            <Typography variant="caption" color="secondary">
                {OPACITY_LABEL}
            </Typography>
            <Stack
                direction="row"
                width={controlColumnWidth}
                className={classes.controlStack}
                gap={1.5}
            >
                <Typography variant="body2" color="secondary">
                    0%
                </Typography>
                <Slider
                    size="small"
                    color="primary"
                    className={classes.opacitySlider}
                    orientation="horizontal"
                    value={getOpacityPercentage()}
                    valueLabelDisplay="off"
                    min={0}
                    max={100}
                    step={5}
                    onChangeCommitted={handleChange}
                    automation-id={applyTrace('opacity-slider')}
                    disabled={isLoading}
                />
                <Typography variant="body2" color="secondary">
                    100%
                </Typography>
            </Stack>
        </Stack>
    );
}
